.expire-time {
  width: 270px;
  height: 170px;
  border-radius: 20px;
  padding: 20px;
  background: linear-gradient(#68d2f1, #3673b9);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: fixed;
  bottom: 12px;
  left: 12px;
  .image-expire {
    width: 80px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -30%);
  }
  .text-notification-expire {
    text-align: center;
    margin-top: 30px;
  }
  .button-extend {
    padding: 5px 10px;
    background: #306fb7;
    border-radius: 5px;
    margin-top: 15px;
    cursor: pointer;
    border: 2px solid white;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}
