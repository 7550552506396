.box-input {
  position: relative;
}

.box-input .toggle-password {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}
