//
// Header Menu
//

// Desktop Mode
@include menu-hor-build-layout(get($header-menu-config, desktop));
@include menu-hor-build-theme(get($header-menu-config, desktop), default);

// Tablet & Mobile Mode
@include menu-ver-build-layout(get($header-menu-config, tablet-and-mobile));
@include menu-ver-build-theme(get($header-menu-config, tablet-and-mobile), default);

// Header Menu Mobile Offcanvas
@include offcanvas-build(header-menu-wrapper, tablet-and-mobile, get($header-menu-config, offcanvas-mobile));

// Desktop Mode
@include media-breakpoint-up(lg) {
	// Header Wrapper
	.header-menu-wrapper {
		align-items: stretch;

		body:not(.aside-enabled) & {
			display: flex;
			flex-direction: row;

			// Logo
			.header-logo {
				padding: 0;
				display: flex;
				align-items: center;
				margin-right: get($page-padding, desktop);
			}
		}
	}

	// Header Menu
	.header-menu {
		display: flex;
		align-items: stretch;

		// Header Nav
		.menu-nav {
			display: flex;
			align-items: stretch;
		}
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
	// Header Wrapper
	.header-menu-wrapper {
		overflow: auto;
		
		// Logo
		.header-logo {
			display: none;
		}
	}
}
