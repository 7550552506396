//
// Header Mobile
//

// Variables
$header-mobile-icon-color: rgba($white, 0.25);
$header-mobile-icon-color-hover: $white;

// Desktop Mode
@include media-breakpoint-up(lg) {
	.header-mobile {
		display: none;
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
	.header-mobile {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 get($page-padding, tablet-and-mobile);
		height: get($header-config, tablet-and-mobile, default, height);
		min-height: get($header-config, tablet-and-mobile, default, height);
		background-color: get($brand-config, base, bg-color);
		position: relative;
		z-index: 3;

		// Fixed Mobile Header Mode
		.header-mobile-fixed & {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			z-index: get($header-config, tablet-and-mobile, fixed, zindex);
			height: get($header-config, tablet-and-mobile, fixed, height);
			min-height: get($header-config, tablet-and-mobile, fixed, height);
		}

		// Topbar Shown Mode
		.topbar-mobile-on & {
			box-shadow: none !important;
		}

		// Burger Icon
		.burger-icon {
			@include burger-icon-theme($header-mobile-icon-color, $header-mobile-icon-color-hover, $header-mobile-icon-color-hover);
		}

		// Button
		.btn {
			i {
				color: $header-mobile-icon-color;
			}

			.svg-icon {
				@include svg-icon-color($header-mobile-icon-color);
			}

			&.active,
			&:hover {
				.svg-icon {
					@include svg-icon-color($header-mobile-icon-color-hover);
				}

				i {
					color: $header-mobile-icon-color-hover;
				}
			}
		}
	}
}
