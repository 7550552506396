.organization-dropdown {
  position: relative;
  .icon-left-organization {
    position: absolute;
    left: 10px;
    display: none;
  }

  &:hover {
    .list-organization {
      display: flex;
    }
    .icon-left-organization {
      display: block;
    }
  }
  .list-organization {
    width: 300px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
    transform: translateX(-100%);
    display: none;
    padding: 10px;
    border-radius: 5px;
    // display: flex;
    flex-direction: column;
    row-gap: 2px;
    .title-organization {
      background: #c7f1fd;
      padding: 8px;
      border-radius: 5px;
      color: #306fb7 !important;
    }
    .organizationItem {
      font-weight: 500;
      padding: 5px;
      border-radius: 5px;
      &:hover {
        background-color: #dfdfdf;
      }
    }
  }
}
