.login-aside {
  background-color: #aee7f8;
  background-position: center;
}

.login-with-th {
  background-color: #00918c;
  color: white;
  border-radius: 50px;
  padding: 10px;
  cursor: pointer;
}

.login-with-google {
  background-color: #333333;
  color: white;
  border-radius: 50px;
  padding: 10px;
  cursor: pointer;
}

.login-with-facebook {
  background-color: #4267b2;
  color: white;
  border-radius: 50px;
  padding: 10px;
  cursor: pointer;
}

.box-input-password {
  position: relative;
}

.box-input-password .toggle-password {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}
