.close-modal {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
  cursor: pointer;
}

.register-success {
  text-align: center;
  margin-top: 30px;
}

.day-trial {
  width: 150px;
  height: 110px;
  border: 3px dashed gray;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  justify-content: space-evenly;
  margin-top: 30px;
  & div:nth-child(2) {
    font-weight: 700;
    font-size: 16px;
  }
}

.wish {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.field-verify.form-control {
  &::placeholder {
    color: #cacaca;
  }
}
